import Vue from 'vue'
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'

import router from './router'
import store from './store'
import App from './App.vue'
import i18n from '@/libs/i18n'
import moment from 'moment'
import axiosIns from '@/libs/axios';
// Global Components
import './global-components'

// 3rd party plugins
import '@/libs/portal-vue'
import '@/libs/toastification'
// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)
Vue.use(BootstrapVue)
// Composition API
Vue.use(VueCompositionAPI)

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')
import VueSweetalert2 from 'vue-sweetalert2';

// Create VueI18n instance with options
i18n.locale = store.state.app.language;
Vue.filter('formatDate', function(value) {
  if (value) {
    
    return moment(String(value)).format('DD.MM.YYYY HH:mm')
  }
})

Vue.filter('formatDateWithoutTime', function(value) {
  if (value) {
   
    return moment(String(value)).format('DD.MM.YYYY')
  }
})
Vue.filter('formatAmount', function(value) {
  if(value){
   return  'CHF ' + Number(value).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&\'');
  }
})
Vue.config.productionTip = false
Vue.use(VueSweetalert2);
new Vue({
  router,
  store,
  i18n,
  BootstrapVue,
  
  render: h => h(App),
}).$mount('#app')
