import { $themeBreakpoints } from '@themeConfig'

export default {
  namespaced: true,
  state: {
    password: null,
    windowWidth: 0,
    shallShowOverlay: false,
    language: 'de',
    sidebar:false,
    fileBase64:'',
    fileType:'',
  },
  getters: {
    currentBreakPoint: state => {
      const { windowWidth } = state
      if (windowWidth >= $themeBreakpoints.xl) return 'xl'
      if (windowWidth >= $themeBreakpoints.lg) return 'lg'
      if (windowWidth >= $themeBreakpoints.md) return 'md'
      if (windowWidth >= $themeBreakpoints.sm) return 'sm'
      return 'xs'
    },
    getFileUrl(){
      return state.fileBase64
    },
    getFileType(){
      return state.fileType
    }
  },
  mutations: {
    UPDATE_PASSWORD(state, val) {
      state.password = val
    },
    UPDATE_LANGUAGE(state, val) {
      state.language = val
    },
    UPDATE_WINDOW_WIDTH(state, val) {
      state.windowWidth = val
    },
    TOGGLE_OVERLAY(state, val) {
      state.shallShowOverlay = val !== undefined ? val : !state.shallShowOverlay
    },
    ON_SIDEBAR_CLICK(state,sidebar){
      state.sidebar = sidebar
    },
    SET_FILE_BASE64(state, url){
      state.fileBase64 = url
    },
    SET_FILE_TYPE(state, url){
      state.fileType = url
    },
  },
  actions: {},
}
